<template>
	<div>
		<el-form :model="formData" v-loading="loading" :rules="rules" ref="ruleForm" status-icon label-width="100px">
			<el-form-item label="密码" prop="password">
				<el-input type="password" placeholder="请输入密码" v-model="formData.password" autocomplete="off"></el-input>
			</el-form-item>
			
		</el-form>
		<div class="drawer-footer">
			<Button type="primary" @click="onSubmit('ruleForm')">提交</Button>
		</div>
	</div>
</template>
<script>
import {request} from "@/library/request.js";
export default {
	data () {
		return {
            id:"",
            loading:false,
			formData:{
				password:"",
			},
			rules:{
				password:[
					{required: true, message: '请输入密码', trigger: 'blur' },
					{min:6, message: '长度最小6个字符', trigger: 'blur' },
					{max:20, message: '长度在40个字符以内', trigger: 'blur' }
				],
			
			}
		}
	},
	methods:{
	
		onSubmit(formName){
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.save()
				} else {
					return false;
				}
			});
		},
		save(){
            this.loading = true
			var that = this
			return request({
				url:'system/agent/password/'+this.id,
				method:"post",
				params:this.formData
			}).then(response  => {
                this.loading = false
				if(response.code==200){
					
					this.$Message.success({
						content:response.msg,
						duration:1.5,
						onClose:function(){	
							that.formData.password=""
							that.$emit('routerCancel')
						}
					})
				}else{
					this.$Message.error({
						content:response.msg,
						duration: 3
					})
				}
			}).then(err => {
				if(err){
                    this.loading = false
					this.$Message.error("系统异常，请稍后重试")
				}
			})
		},
	},
     watch:{
        '$route.params': {
                handler(newVal,oldVal){
                    this.id = newVal.id
                },
            immediate: true
        }
    }
}
</script>
<style scoped>
	.drawer-footer{
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
		padding: 10px 16px;
		text-align: right;
		background: #fff;
	}
</style>